import React, { useEffect } from 'react'
import './homeScreen.css'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getFarmersRequest } from '../../redux/actions/getFarmersAction'
import { ColorRing } from 'react-loader-spinner'

const HomeScreen = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const queryParams = new URLSearchParams(window.location.search)

    const category = queryParams.get('category')
    const product = queryParams.get('product')
    const center = queryParams.get('center')

    if (category) {
        sessionStorage.setItem('category', category)
    }

    if (product) {
        sessionStorage.setItem('product', product)
    }

    if (center) {
        sessionStorage.setItem('center', center)
    }

    useEffect(() => {
        let requestFarmers = {
            center: sessionStorage.getItem('center'),
        }
        dispatch(getFarmersRequest({ requestFarmers }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const farmersData = useSelector(
        (state) => state.getFarmersReducer.resFarmers
    )

    const farmersLoading = useSelector(
        (state) => state.getFarmersReducer.loading
    )

    return (
        <div className="homeScreenWrapper">
            {farmersLoading && farmersData === null && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <ColorRing
                        visible={true}
                        height="40"
                        width="40"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={['#652078']}
                    />
                </div>
            )}
            {farmersData?.data?.length === 0 && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <h3>No Leads Found</h3>
                </div>
            )}
            {farmersData?.data.map((obj, index) => (
                <div
                    className="homeScreenTiles"
                    key={index}
                    onClick={() => navigate('/interested', { state: { obj } })}
                >
                    <div>{obj.name}</div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            color: '#fff',
                        }}
                    >
                        <div
                            style={{
                                background: '#fd7e50',
                                padding: '5px 35px 5px 35px',
                                borderRadius: '4px 0px 4px 0px',
                            }}
                        >
                            View
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default HomeScreen
