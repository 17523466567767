import * as types from '../types'

export const getProductsRequest = ({ requestPhone }) => {
    return {
        type: types.GET_PRODUCTS_REQUEST,
        payload: {
            requestPhone,
        },
    }
}

export const getProductsSuccess = ({ resProducts }) => {
    return {
        type: types.GET_PRODUCTS_SUCCESS,
        payload: {
            resProducts,
        },
    }
}

export const getProductsFailure = (error) => {
    return {
        type: types.GET_PRODUCTS_FAILURE,
        payload: {},
        error: error,
    }
}
