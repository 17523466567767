import * as types from '../types'

export const getFarmersRequest = ({ requestFarmers }) => {
    return {
        type: types.GET_FARMERS_REQUEST,
        payload: {
            requestFarmers,
        },
    }
}

export const getFarmersSuccess = ({ resFarmers }) => {
    return {
        type: types.GET_FARMERS_SUCCESS,
        payload: {
            resFarmers,
        },
    }
}

export const getFarmersFailure = (error) => {
    return {
        type: types.GET_FARMERS_FAILURE,
        payload: {},
        error: error,
    }
}
