import { takeLatest, call, put } from 'redux-saga/effects'
import {
    statusChangeFailure,
    statusChangeSuccess,
} from '../actions/getStatusAction'
import { returnErrors, clearErrors } from '../actions/errorAction'
import * as _api from '../../api'
import * as types from '../types'

function* asyncStatusChangeSaga(action) {
    try {
        yield put(clearErrors())
        let resStatusChange = yield call(_api.statusChange, {
            requestStatusChange: action.payload.requestStatusChange,
        })
        yield put(statusChangeSuccess({ resStatusChange }))
    } catch (e) {
        yield put(returnErrors({ msg: e.message, status: true }))
        yield put(statusChangeFailure({ error: e }))
    }
}
export default function* getStatusChangeSaga() {
    yield takeLatest(types.STATUS_CHANGE_REQUEST, asyncStatusChangeSaga)
}
