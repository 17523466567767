import React, { useEffect, useState } from 'react'
import './interestedScreen.css'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getProductsRequest } from '../../redux/actions/getProductsAction'
import { ColorRing } from 'react-loader-spinner'
import {
    statusChangeRequest,
    statusChangeReset,
} from '../../redux/actions/getStatusAction'
import { Toaster, toast } from 'react-hot-toast'
import { FaArrowLeft } from 'react-icons/fa'

const InterestedProduct = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const [leadStatus, setLeadStatus] = useState([])

    useEffect(() => {
        let requestPhone = {
            phone: location.state.obj.phone,
        }
        dispatch(getProductsRequest({ requestPhone }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const leadsData = useSelector(
        (state) => state.getProductsReducer.resProducts?.data
    )

    const leadsStatusData = useSelector(
        (state) => state.getStatusChangeReducer.resStatusChange?.status
    )

    const leadsLoading = useSelector(
        (state) => state.getProductsReducer.loading
    )

    useEffect(() => {
        let statusData = []
        if (leadsData) {
            leadsData.forEach((data) => {
                statusData.push({
                    id: data.id,
                    status: data.status,
                })
            })
        }
        setLeadStatus(statusData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leadsData])

    useEffect(() => {
        if (leadsStatusData) {
            toast.success('Lead Status has been updated', {
                position: 'top-right',
                duration: 3000,
            })
            setTimeout(() => {
                dispatch(statusChangeReset())
            }, 3000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leadsStatusData])

    const filteredData = leadsData?.filter(
        (item) =>
            item.category?.toLowerCase() ===
                sessionStorage.getItem('category')?.toLowerCase() &&
            item.product?.toLowerCase() ===
                sessionStorage.getItem('product')?.toLowerCase()
    )

    const handleChange = (e, id) => {
        const updatedData = leadStatus.map((data) => {
            if (data.id === id) {
                return { ...data, status: e.target.value }
            }
            return data
        })
        setLeadStatus(updatedData)
        let requestStatusChange = {
            id,
            status: e.target.value,
        }
        dispatch(statusChangeRequest({ requestStatusChange }))
    }

    return (
        <div className="interestedWrapper">
            <div
                onClick={() => navigate('/')}
                style={{
                    margin: '10px 0 20px 0',
                    fontWeight: '600',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                }}
            >
                <FaArrowLeft />
                Back to Home
            </div>
            {leadsLoading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <ColorRing
                        visible={true}
                        height="40"
                        width="40"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={['#652078']}
                    />
                </div>
            )}
            {filteredData?.length === 0 && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <h3>No Leads Found</h3>
                </div>
            )}
            {filteredData?.map((data, index) => (
                <div key={index} style={{ marginBottom: '2em' }}>
                    <div className="tableRowsContainer">
                        <div className="tableRows status">
                            <h4 style={{ color: '#333' }}>Status</h4>
                            <select
                                style={{ padding: '5px' }}
                                value={
                                    leadStatus?.find(
                                        (item) => item.id === data.id
                                    )?.status || ''
                                }
                                onChange={(e) => handleChange(e, data.id)}
                            >
                                <option value="">Select Status</option>
                                <option value="hot">Hot</option>
                                <option value="converted">Converted</option>
                                <option value="not interested">
                                    Not Interested
                                </option>
                                <option value="lost">Lost</option>
                            </select>
                        </div>
                    </div>
                    {Object.entries(data).map(
                        ([key, value]) =>
                            key !== 'id' &&
                            value && (
                                <div className="tableRowsContainer" key={key}>
                                    <div
                                        className="tableRows tableRowsHeader"
                                        style={{ width: '35%' }}
                                    >
                                        {key?.replace(/_/g, ' ')?.toUpperCase()}
                                    </div>
                                    <div
                                        className="tableRows tableRowsValue"
                                        style={{ width: '65%' }}
                                    >
                                        {value}
                                    </div>
                                </div>
                            )
                    )}
                </div>
            ))}
            <Toaster />
        </div>
    )
}

export default InterestedProduct
