export const API_ROUTES = {
    USER_DATA: '/app/get_leads/users/',
    USER_INTEREST: '/app/get_leads/user/',
    STATUS_CHANGE: '/app/update_farmer_equipment_status/',
}

export const API_BASE_URL = {
    PARENT_URL: process.env.REACT_APP_PARENT_URL
        ? process.env.REACT_APP_PARENT_URL
        : 'https://crop-advisory-staging.azurewebsites.net',
}

export const API_BASE_URL_DELETE = {
    PARENT_URL: process.env.REACT_APP_PARENT_URL_DELETE
        ? process.env.REACT_APP_PARENT_URL_DELETE
        : 'https://staging.carnot-traclytics.com',
}
