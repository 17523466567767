import { useState } from 'react'
import './reactform.css'
import { API_BASE_URL_DELETE } from '../../config'

function ReactForm() {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        selectedApp: '',
        reason: '',
    })

    const [errors, setErrors] = useState({})

    const [formSubmitted, setFormSubmitted] = useState(false)

    const [response, setResponse] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    function handleChange(event) {
        const { name, value } = event.target

        if (name === 'phone' && !/^\d*$/.test(value)) return //allows user to only enter digits in phone number

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }))

        // Clear the error related to this field
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: null,
        }))
    }

    function validate() {
        const newErrors = {}
        if (!formData.name) newErrors.name = 'Name is required'
        if (!formData.phone) newErrors.phone = 'Phone number is required'
        else if (!/^\d{10}$/.test(formData.phone))
            newErrors.phone = 'Phone number must be 10 digits'

        if (formData.email && !/\S+@\S+\.\S+/.test(formData.email))
            newErrors.email = 'Email address is invalid'
        if (!formData.selectedApp)
            newErrors.selectedApp = 'Please select an app'
        return newErrors
    }

    async function handleSubmit(event) {
        event.preventDefault()
        setIsLoading(true)
        const newErrors = validate()
        if (Object.keys(newErrors).length === 0) {
            //no errors found
            setFormSubmitted(true)

            try {
                const payload = {
                    name: formData.name,
                    phone: formData.phone,
                    email: formData.email,
                    selected_app: formData.selectedApp,
                    reason: formData.reason,
                }

                const response = await fetch(
                    `${API_BASE_URL_DELETE.PARENT_URL}/ops/account_deletion_request/`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    }
                )

                if (!response.ok) {
                    throw new Error(
                        `HTTP error with status code: ${response.status}`
                    )
                }

                const data = await response.json()
                setResponse(data)

                // reload the form after 3 seconds
                setTimeout(() => {
                    window.location.reload()
                }, 3000)
            } catch (error) {
                console.error('Error caught: ', error)
                setResponse({
                    status: false,
                    message: 'Error. Please try again.',
                })
            } finally {
                setIsLoading(false)
            }
        } else {
            setErrors(newErrors)
        }
    }

    return (
        <>
            <nav>
                <img
                    src="https://www.carnot.co.in/img/main/Carnot-Logo.svg"
                    alt="Carnot Logo"
                />
            </nav>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div className="form-div">
                    <h1>Delete Account Requests</h1>
                    <form onSubmit={handleSubmit} noValidate>
                        <label>
                            Name*{' '}
                            {errors.name && (
                                <span className="error">{errors.name}</span>
                            )}
                            <input
                                type="text"
                                className={`form-input ${
                                    errors.name ? 'error-input' : ''
                                }`}
                                name="name"
                                onChange={handleChange}
                                value={formData.name}
                                placeholder="Ex: Priya Gupta"
                            />
                        </label>
                        <label>
                            Registered Phone number*{'  '}
                            {errors.phone && (
                                <span className="error">{errors.phone}</span>
                            )}
                            <input
                                type="tel"
                                className={`form-input ${
                                    errors.phone ? 'error-input' : ''
                                }`}
                                name="phone"
                                onChange={handleChange}
                                value={formData.phone}
                                placeholder="Ex: 1234567890"
                            />
                        </label>
                        <label>
                            Email address{'  '}
                            {errors.email && (
                                <span className="error">{errors.email}</span>
                            )}
                            <input
                                type="email"
                                className={`form-input ${
                                    errors.email ? 'error-input' : ''
                                }`}
                                name="email"
                                onChange={handleChange}
                                value={formData.email}
                                placeholder="example@example.com"
                            />
                        </label>
                        <label>
                            Select app*{'  '}
                            {errors.selectedApp && (
                                <span className="error">
                                    {errors.selectedApp}
                                </span>
                            )}
                            <select
                                name="selectedApp"
                                onChange={handleChange}
                                className="form-input"
                                value={formData.selectedApp}
                            >
                                <option value="">Select...</option>
                                <option value="Krish-e: Kheti ke liye app">
                                    Krish-e: Kheti ke liye app
                                </option>
                                <option value="Krish-e Takneek Plot">
                                    Krish-e Takneek Plot
                                </option>
                                <option value="Krish-e Nidaan: Agriculture app">
                                    Krish-e Nidaan: Agriculture app
                                </option>
                                <option value="TractorWorld Business">
                                    TractorWorld Business
                                </option>
                                <option value="Krish-e Smart Kit">
                                    Krish-e Smart Kit
                                </option>
                            </select>
                        </label>
                        <label>
                            Reason
                            <textarea
                                className="form-input"
                                name="reason"
                                onChange={handleChange}
                                value={formData.reason}
                                placeholder="Enter reason"
                            />
                        </label>
                        {formSubmitted && isLoading ? (
                            <p className="error-message">Loading...</p>
                        ) : (
                            <p
                                className={`${
                                    response.status
                                        ? 'success-message'
                                        : 'error-message'
                                }`}
                            >
                                {response.message}
                            </p>
                        )}
                        <button
                            type="submit"
                            className={`form-button ${
                                formSubmitted ? 'disable-button' : ''
                            }`}
                            disabled={formSubmitted}
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ReactForm
