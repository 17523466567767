// ERROR TYPES
export const GET_ERRORS = 'GET_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

//GET FARMERS
export const GET_FARMERS_REQUEST = 'GET_FARMERS_REQUEST'
export const GET_FARMERS_SUCCESS = 'GET_FARMERS_SUCCESS'
export const GET_FARMERS_FAILURE = 'GET_FARMERS_FAILURE'

//GET FARMERS
export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE'

//STATUS CHANGE
export const STATUS_CHANGE_REQUEST = 'STATUS_CHANGE_REQUEST'
export const STATUS_CHANGE_SUCCESS = 'STATUS_CHANGE_SUCCESS'
export const STATUS_CHANGE_FAILURE = 'STATUS_CHANGE_FAILURE'
export const STATUS_CHANGE_RESET = 'STATUS_CHANGE_RESET'
