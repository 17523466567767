import { takeLatest, call, put } from 'redux-saga/effects'
import {
    getFarmersFailure,
    getFarmersSuccess,
} from '../actions/getFarmersAction'
import { returnErrors, clearErrors } from '../actions/errorAction'
import * as _api from '../../api'
import * as types from '../types'

function* asyncGetFarmersSaga(action) {
    try {
        yield put(clearErrors())
        let resFarmers = yield call(_api.getFarmers, {
            requestFarmers: action.payload.requestFarmers,
        })
        yield put(getFarmersSuccess({ resFarmers }))
    } catch (e) {
        yield put(returnErrors({ msg: e.message, status: true }))
        yield put(getFarmersFailure({ error: e }))
    }
}
export default function* getFarmersSaga() {
    yield takeLatest(types.GET_FARMERS_REQUEST, asyncGetFarmersSaga)
}
