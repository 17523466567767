import * as types from '../types'

const initialState = {
    loading: false,
    requestStatusChange: null,
    resStatusChange: null,
    error: null,
}

export default function getStatusChangeReducer(state = initialState, action) {
    switch (action.type) {
        case types.STATUS_CHANGE_REQUEST:
            return {
                ...state,
                loading: true,
                requestStatusChange: action.payload.requestStatusChange,
            }

        case types.STATUS_CHANGE_SUCCESS:
            return {
                ...state,
                loading: false,
                resStatusChange: action.payload.resStatusChange,
            }

        case types.STATUS_CHANGE_FAILURE:
            return {
                ...state,
                loading: false,
                requestStatusChange: null,
                resStatusChange: null,
                error: action.payload.error,
            }

        case types.STATUS_CHANGE_RESET:
            return {
                ...state,
                loading: false,
                requestStatusChange: null,
                resStatusChange: null,
                error: null,
            }

        default:
            return state
    }
}
