import axios from 'axios'
import { API_BASE_URL, API_ROUTES } from '../config'

export const getFarmers = async ({ requestFarmers }) => {
    try {
        const { data } = await axios({
            method: 'get',
            url: `${API_BASE_URL.PARENT_URL}${API_ROUTES.USER_DATA}${requestFarmers.center}`,
        })
        return data
    } catch (error) {
        throw error
    }
}

export const getProducts = async ({ requestPhone }) => {
    try {
        const { data } = await axios({
            method: 'get',
            url: `${API_BASE_URL.PARENT_URL}${API_ROUTES.USER_INTEREST}${requestPhone.phone}`,
        })
        return data
    } catch (error) {
        throw error
    }
}

export const statusChange = async ({ requestStatusChange }) => {
    try {
        let id = requestStatusChange?.id
        delete requestStatusChange['id']
        const { data } = await axios({
            method: 'patch',
            url: `${API_BASE_URL.PARENT_URL}${API_ROUTES.STATUS_CHANGE}${id}`,
            data: requestStatusChange,
        })
        return data
    } catch (error) {
        throw error
    }
}
