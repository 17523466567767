import * as types from '../types'

const initialState = {
    loading: false,
    requestPhone: null,
    resProducts: null,
    error: null,
}

export default function getProductsReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_PRODUCTS_REQUEST:
            return {
                ...state,
                loading: true,
                requestPhone: action.payload.requestPhone,
            }

        case types.GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                resProducts: action.payload.resProducts,
            }

        case types.GET_PRODUCTS_FAILURE:
            return {
                ...state,
                loading: false,
                requestPhone: null,
                resProducts: null,
                error: action.payload.error,
            }

        default:
            return state
    }
}
