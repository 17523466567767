import * as types from '../types'

export const statusChangeRequest = ({ requestStatusChange }) => {
    return {
        type: types.STATUS_CHANGE_REQUEST,
        payload: {
            requestStatusChange,
        },
    }
}

export const statusChangeSuccess = ({ resStatusChange }) => {
    return {
        type: types.STATUS_CHANGE_SUCCESS,
        payload: {
            resStatusChange,
        },
    }
}

export const statusChangeFailure = (error) => {
    return {
        type: types.STATUS_CHANGE_FAILURE,
        payload: {},
        error: error,
    }
}

export const statusChangeReset = () => {
    return {
        type: types.STATUS_CHANGE_RESET,
        payload: {},
    }
}
