import { takeLatest, call, put } from 'redux-saga/effects'
import {
    getProductsFailure,
    getProductsSuccess,
} from '../actions/getProductsAction'
import { returnErrors, clearErrors } from '../actions/errorAction'
import * as _api from '../../api'
import * as types from '../types'

function* asyncGetProductsSaga(action) {
    try {
        yield put(clearErrors())
        let resProducts = yield call(_api.getProducts, {
            requestPhone: action.payload.requestPhone,
        })
        yield put(getProductsSuccess({ resProducts }))
    } catch (e) {
        yield put(returnErrors({ msg: e.message, status: true }))
        yield put(getProductsFailure({ error: e }))
    }
}
export default function* getProductsSaga() {
    yield takeLatest(types.GET_PRODUCTS_REQUEST, asyncGetProductsSaga)
}
