import { combineReducers } from 'redux'
import errorReducer from './errorReducer'
import getFarmersReducer from './getFarmersReducer'
import getProductsReducer from './getProductsReducer'
import getStatusChangeReducer from './getStatusReducer'

const rootReducer = combineReducers({
    errorReducer,
    getFarmersReducer,
    getProductsReducer,
    getStatusChangeReducer,
})

export default rootReducer
