import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { HomeScreen, InterestedProduct, ReactForm } from '../screens'

const Path = () => {
    return (
        <>
            <Router>
                <Routes>
                    <Route exact path={`/`} element={<HomeScreen />}></Route>
                </Routes>
                <Routes>
                    <Route
                        exact
                        path={`/interested`}
                        element={<InterestedProduct />}
                    ></Route>
                </Routes>
                <Routes>
                    <Route
                        exact
                        path={`/delete`}
                        element={<ReactForm />}
                    ></Route>
                </Routes>
            </Router>
        </>
    )
}

export default Path
