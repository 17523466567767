import * as types from '../types'

const initialState = {
    loading: false,
    requestFarmers: null,
    resFarmers: null,
    error: null,
}

export default function getFarmersReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_FARMERS_REQUEST:
            return {
                ...state,
                loading: true,
                requestFarmers: action.payload.requestFarmers,
            }

        case types.GET_FARMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                resFarmers: action.payload.resFarmers,
            }

        case types.GET_FARMERS_FAILURE:
            return {
                ...state,
                loading: false,
                requestFarmers: null,
                resFarmers: null,
                error: action.payload.error,
            }

        default:
            return state
    }
}
